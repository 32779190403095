import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getLayoutPostSize } from '../../../common/selectors/app-settings-selectors';
import CreatePostButtonDesktop from './create-post-button-desktop';
import PageTitle from './page-title';
import PageTabs from './page-tabs';
import styles from './my-posts-frame-desktop.scss';

const MyPostsFrameDesktop = ({ children, layoutPostSize }) => (
  <div className={styles.root}>
    <div style={{ width: layoutPostSize }}>
      <div className={styles.header}>
        <PageTitle />
        <CreatePostButtonDesktop />
      </div>
      <div className={styles.tabs}>
        <PageTabs />
      </div>
      {children}
    </div>
  </div>
);

MyPostsFrameDesktop.propTypes = {
  layoutPostSize: PropTypes.number.isRequired,
};

const mapRuntimeToProps = (state, _ownProps, _actions, host) => ({
  layoutPostSize: getLayoutPostSize({ state, hostWidth: host.dimensions.width }),
});

export default connect(mapRuntimeToProps)(MyPostsFrameDesktop);
