import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Counter from '../counter';
import styles from './counter-compact.scss';

const CounterCompact = ({ children, dataHook, tabIndex, ...counterProps }) => (
  <span className={classNames(styles.container, counterProps.className)} data-hook={dataHook} tabIndex={tabIndex}>
    {children}
    <Counter {...counterProps} className={styles.count} />
  </span>
);

CounterCompact.propTypes = {
  children: PropTypes.node,
  dataHook: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default CounterCompact;
