import { flowRight, isUndefined, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  isLayoutPGSideBySide,
  isLayoutPGOneColumn,
  isLayoutTextOnImage,
  isLayoutSlider,
  isLayoutList,
  SECTIONS,
  SECTION_POST_LIST,
  SECTION_MY_POSTS,
  getPostCover,
  getVideoSettingsWithProGalleryValues,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  isExperimentEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_AUTO_HEIGHT,
  EXPERIMENT_USE_LAYOUT_FIXER,
  EXPERIMENT_PG_CARDS_PER_ROW,
  EXPERIMENT_USE_RESOLVED_COVER_IMAGE,
  EXPERIMENT_ADD_IMAGE_ENCODING,
} from '@wix/communities-blog-experiments';

import { connect } from '../runtime-context';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import withTranslate from '../../hoc/with-translate';
import withExperiment from '../../hoc/with-experiment';
import classNames from 'classnames';

import { ProGallery, GALLERY_CONSTS } from 'pro-gallery'; // eslint-disable-line import/no-extraneous-dependencies

import Wix from '../../services/wix-sdk-polyfill';
import { getLayoutConfig } from '../../services/layout-config';
import { getWrappersHorizontalMargin, getContainerWidth } from '../../services/post-list-pro-gallery';
import withDeviceType from '../../hoc/with-device-type';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import {
  getIsPostListFullWidthEnabled,
  getLayoutPostSize,
  getLayoutSpacing,
  getLayoutContentHeight,
  getFeedBorderColor,
  getLayoutImageRatio,
  getLayoutMargins,
  getLayoutImageAlignment,
  getLayoutImageProportions,
  getLayoutImageWidth,
  getLayoutImageResizingMode,
  getIsCreatedWithResponsiveEditor,
  getLayoutSidesPadding,
  getLayoutImageCropType,
  getUseMobileLayoutSettings,
  getLayoutCardsRowType,
  getLayoutPostsPerRow,
  getLayoutContentHeightType,
} from '../../selectors/app-settings-selectors';
import PostListItemProGallery from '../post-list-item-pro-gallery';
import { getPostPageSectionUrl, getIsTemplate } from '../../store/topology/topology-selectors';
import LoadMore from '../load-more/load-more';
import Loader from '../loader';
import { resizeMediaUrl } from './resizeMediaUrl';
import { createOptions, toLayoutFixerStyles } from './gallery-options';
import { isHoveringBehaviourNeverShow } from '../../selectors/pro-gallery-options-selectors';
import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';
import { isClickTargetItemMedia, isPGEventItemClicked } from '../../constants/pro-gallery-events';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import { getIsMobile, getIsRTL } from '../../store/basic-params/basic-params-selectors';

import { getIsDemoMode } from '../../store/instance-values/instance-values-selectors';
import styles from './post-list-pro-gallery.scss';
import withMediaHosts from '../../hoc/with-media-hosts';
import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';
import { getIsInIFrame } from '../../selectors/is-in-iframe';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import calculateContentHeight from './calculate-content-height';
import { getPostListCssVars } from '../../services/post-list-css-vars';

const getPGEmptyCoverImage = (layoutType) => ({
  height: 1,
  width: 10000,
  ...(isLayoutTextOnImage(layoutType) || isLayoutSlider(layoutType) ? { type: 'text' } : {}),
});

const createInfoRenderer =
  ({
    allPosts,
    prevAllPosts,
    layoutType,
    layoutName,
    section,
    layoutSidesPadding,
    canSeeMoreButton,
    hideAuthorBadge,
    textBoxAlignment,
    options,
    onLikeClick,
    getPostClassName,
    visibleActions,
  }) =>
  (itemProps, placement) => {
    if (isLayoutList(layoutType) && placement === 'SHOW_BELOW') {
      const containerClassName = classNames(
        getPostClassName('background-color'),
        styles.listLayoutFullHeightBackground,
      );
      return <div className={containerClassName}></div>;
    }

    const { itemConfig } = getLayoutConfig(layoutType);
    const currentPost =
      allPosts.find((post) => resolveId(post) === itemProps.id) ||
      prevAllPosts.find((post) => resolveId(post) === itemProps.id);

    return (
      <PostListItemProGallery
        itemConfig={itemConfig}
        type={layoutName}
        postId={itemProps.id}
        post={currentPost}
        onLikeClick={onLikeClick}
        galleryOptions={options}
        layoutSidesPadding={layoutSidesPadding}
        section={section}
        canSeeMoreButton={canSeeMoreButton}
        hideAuthorBadge={hideAuthorBadge}
        textBoxAlignment={textBoxAlignment}
        visibleActions={visibleActions}
      />
    );
  };

class PostListProGallery extends Component {
  isPrerenderMode = this.props.useLayoutFixerExperimentEnabled;

  componentDidMount() {
    if (this.props.createdWithResponsiveEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  getPostCoverImage = (post) => {
    const { imageHost, videoHost, useResolvedCoverImage } = this.props;
    return getPostCover(post, imageHost, videoHost, useResolvedCoverImage);
  };

  getItemMetadata(post, hideCoverImage, emptyCoverImage) {
    const { shouldRender, imageMetadata, videoMetadata } = this.getPostCoverImage(post);

    if (hideCoverImage || !shouldRender) {
      return {
        ...emptyCoverImage,
        alt: post.title,
      };
    }

    if (imageMetadata) {
      return {
        type: 'image',
        height: imageMetadata.height,
        width: imageMetadata.width,
        alt: post.title,
      };
    } else if (videoMetadata) {
      const { height, width, thumbnail_height, thumbnail_width, thumbnail_url } = videoMetadata;
      return {
        type: 'video',
        height: height || thumbnail_height,
        width: width || thumbnail_width,
        poster: {
          url: thumbnail_url,
          height: thumbnail_height,
          width: thumbnail_width,
        },
        alt: post.title,
      };
    }
  }

  getItemUrl(post) {
    const { shouldRender, imageMetadata, videoMetadata } = this.getPostCoverImage(post);

    if (this.props.hideCoverImage || !shouldRender) {
      return '';
    }

    if (imageMetadata) {
      return imageMetadata.file_name;
    } else if (videoMetadata) {
      return videoMetadata.video_url;
    }
  }

  resizeMediaUrl = (item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint) => {
    return resizeMediaUrl({
      item,
      originalUrl,
      resizeMethod,
      requiredWidth,
      requiredHeight,
      sharpParams,
      focalPoint,
      encode: this.props.isImageEncodingEnabled,
    });
  };

  renderProGallery() {
    const {
      t,
      allPosts,
      borderColor,
      borderWidth,
      createdWithResponsiveEditor,
      hostWidth,
      layoutContentHeight,
      layoutImageAlignment,
      layoutImageProportions,
      layoutImageWidth,
      layoutImageResizingMode,
      layoutImageRatio,
      layoutMargins,
      layoutName,
      layoutPostSize,
      layoutCardsRowType,
      layoutPostsPerRow,
      layoutSidesPadding,
      layoutSpacing,
      layoutType,
      layoutImageCropType,
      onLikeClick,
      rootWidth,
      isRTL,
      section,
      hideAuthorBadge,
      canSeeMoreButton,
      className,
      hideCoverImage,
      layoutAutoHeightEnabled,
      isMobile,
      layoutOptions = {},
      videoSettings,
      useLayoutFixerExperimentEnabled,
      useMobilePostListLayout,
      getPostClassName,
      visibleActions,
    } = this.props;

    const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
    const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);
    const layoutTextOnImage = isLayoutTextOnImage(layoutType);
    const layoutSlider = isLayoutSlider(layoutType);
    const layoutImageRatioValue = [null, 16 / 9, 4 / 3, 1, 3 / 4, 9 / 16, 16 / 7][Number(layoutImageRatio)];
    const textBoxAlignment = [null, TEXT_PLACEMENT.SHOW_ON_THE_LEFT, TEXT_PLACEMENT.SHOW_ON_THE_RIGHT][
      Number(layoutImageAlignment)
    ];

    const container = {};

    if (section === SECTION_MY_POSTS) {
      container.width = layoutPostSize;
    } else {
      container.width = getContainerWidth({
        width: rootWidth,
        layoutMargins: isMobile && !useMobilePostListLayout ? 0 : layoutMargins,
        createdWithResponsiveEditor,
        layoutPGSideBySide,
        layoutPGOneColumn,
        layoutPostSize,
        layoutSlider,
        borderWidth,
      });
    }

    const options = createOptions(
      layoutType,
      {
        layoutPostSize: isLayoutSlider(layoutType) ? hostWidth : layoutPostSize,
        layoutCardsRowType,
        layoutPostsPerRow,
        layoutSpacing,
        layoutContentHeight,
        layoutImageCropType,
        layoutImageRatio: layoutImageRatioValue,
        textBoxAlignment,
        layoutImageProportions,
        layoutImageWidth,
        layoutImageResizingMode,
        isRTL,
        videoSettings,
        containerWidth: container.width,
        hideCoverImage,
        layoutAutoHeightEnabled,
        ...layoutOptions,
      },
      {
        width: borderWidth,
        color: borderColor,
      },
    );

    if (!isEqual(this.options, options)) {
      this.options = options;
    }

    if (
      allPosts !== this.prevAllPosts ||
      hideCoverImage !== this.prevHideCoverImage ||
      layoutType !== this.prevLayoutType ||
      layoutSidesPadding !== this.prevLayoutSidesPadding ||
      canSeeMoreButton !== this.prevCanSeeMoreButton ||
      textBoxAlignment !== this.prevTextBoxAlignment
    ) {
      const emptyCoverImage = getPGEmptyCoverImage(layoutType);
      this.items = allPosts.map((post) => {
        return {
          itemId: resolveId(post),
          metaData: this.getItemMetadata(post, hideCoverImage, emptyCoverImage),
          url: this.getItemUrl(post),
        };
      });

      this.postInfoRenderer = createInfoRenderer({
        prevAllPosts: this.prevAllPosts || [],
        allPosts,
        layoutType,
        layoutName,
        section,
        layoutSidesPadding,
        canSeeMoreButton,
        hideAuthorBadge,
        textBoxAlignment,
        options: this.options,
        onLikeClick,
        getPostClassName,
        visibleActions,
      });
      if (allPosts !== this.prevAllPosts) {
        this.prevAllPosts = allPosts;
      }
      this.prevHideCoverImage = hideCoverImage;
      this.prevLayoutType = layoutType;
      this.prevLayoutSidesPadding = layoutSidesPadding;
      this.prevCanSeeMoreButton = canSeeMoreButton;
      this.prevTextBoxAlignment = textBoxAlignment;
    }

    const scrollingElement = () => {
      try {
        if (
          typeof window !== 'undefined' &&
          (window.top === window.self || createdWithResponsiveEditor || !this.props.isInIFrame)
        ) {
          // todo in editorX preview return mock (for iframe)
          // OOI
          return window;
        } else {
          if (typeof window !== 'undefined' && (this.props.isTemplate || this.props.isDemoMode)) {
            return window;
          }

          // iFrame
          // return a "mock" of the window
          return {
            addEventListener: (eventName, callback) => {
              Wix.addEventListener(eventName.toUpperCase(), callback);
            },
            removeEventListener: (eventName, callback) => {
              Wix.removeEventListener(eventName.toUpperCase(), callback);
            },
          };
        }
      } catch (e) {
        console.error('Cannot get scrolling element', e);
        return {};
      }
    };

    const { navigateProGalleryWithinPostPage } = this.props;
    const eventsListener = (eventName, eventData) => {
      if (!layoutTextOnImage && isPGEventItemClicked(eventName) && isClickTargetItemMedia(eventData)) {
        const currentPost = allPosts.find((post) => resolveId(post) === eventData.id);

        if (currentPost.slug && videoSettings.play !== 'onClick') {
          navigateProGalleryWithinPostPage(currentPost.slug);
        }
      }
    };

    const horizontalMargin = getWrappersHorizontalMargin({
      layoutPGSideBySide,
      layoutPGOneColumn,
      layoutMargins,
      createdWithResponsiveEditor,
    });

    const wrapperStyles = {
      ...getPostListCssVars(),
    };

    if ((layoutPGSideBySide || layoutPGOneColumn) && section !== SECTION_MY_POSTS) {
      wrapperStyles.maxWidth = layoutPostSize + borderWidth * 2;
    }

    if ((section !== SECTION_POST_LIST && section !== SECTION_MY_POSTS) || layoutPGSideBySide) {
      wrapperStyles.marginTop = createdWithResponsiveEditor ? 20 : layoutMargins;
      wrapperStyles.marginBottom = createdWithResponsiveEditor ? 0 : layoutMargins;
      wrapperStyles.marginLeft = horizontalMargin;
      wrapperStyles.marginRight = horizontalMargin;
    }

    if (isMobile) {
      wrapperStyles.margin = 0;
    }

    if (layoutSlider) {
      const containerHeight = (rootWidth - layoutMargins * 2) / layoutImageRatioValue + layoutContentHeight;
      wrapperStyles.height = containerHeight;
      container.height = containerHeight;
    }

    const domId = this.props.domId || 'pro-blog';

    return (
      <div
        data-hook="post-list-pro-gallery-container"
        style={wrapperStyles}
        className={classNames(
          `post-list-pro-gallery-${layoutName}`,
          textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT ? 'gallery-left-align' : 'gallery-right-align',
          className,
        )}
      >
        <ProGallery
          key={`pg-posts-${layoutPostSize}`} // side by side layout doesn't update on layoutPostSize option change
          domId={domId}
          allowSSR={true}
          items={this.items}
          totalItemsCount={this.items.length}
          options={this.options}
          container={container}
          scrollingElement={scrollingElement}
          eventsListener={eventsListener}
          viewMode={this.props.viewMode}
          formFactor={this.props.formFactor}
          customHoverRenderer={isHoveringBehaviourNeverShow(options) ? null : this.postInfoRenderer}
          customInfoRenderer={this.postInfoRenderer}
          customSlideshowInfoRenderer={this.postInfoRenderer}
          resizeMediaUrl={this.resizeMediaUrl}
          proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
          isPrerenderMode={this.isPrerenderMode}
        />
        {useLayoutFixerExperimentEnabled ? (
          <LayoutFixer
            items={this.items.map((item) => ({
              id: item.itemId,
              width: item.metaData.width,
              height: item.metaData.height,
            }))}
            domId={domId}
            isPrerenderMode={this.isPrerenderMode}
            styles={toLayoutFixerStyles(this.options)}
          />
        ) : null}
      </div>
    );
  }

  render() {
    if (!this.props.loadMore) {
      return this.renderProGallery();
    }

    const { isLoading, allPosts, entityCount, loadMore, pageStart } = this.props;

    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        {this.renderProGallery()}
      </LoadMore>
    );
  }
}

PostListProGallery.propTypes = {
  t: PropTypes.func,
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
  postPageSectionUrl: PropTypes.string,
  hostWidth: PropTypes.number,
  navigateProGalleryWithinPostPage: PropTypes.func,
  layoutPostSize: PropTypes.number,
  layoutCardsRowType: PropTypes.number,
  layoutPostsPerRow: PropTypes.number,
  layoutSpacing: PropTypes.number,
  layoutContentHeight: PropTypes.number,
  layoutAutoHeightEnabled: PropTypes.bool,
  layoutImageRatio: PropTypes.number,
  layoutMargins: PropTypes.number,
  layoutImageAlignment: PropTypes.number,
  layoutImageProportions: PropTypes.number,
  layoutImageWidth: PropTypes.number,
  layoutImageResizingMode: PropTypes.number,
  viewMode: PropTypes.string,
  formFactor: PropTypes.oneOf([GALLERY_CONSTS.formFactor.MOBILE, GALLERY_CONSTS.formFactor.DESKTOP]),
  borderWidth: PropTypes.number.isRequired,
  layoutSidesPadding: PropTypes.number.isRequired,
  layoutImageCropType: PropTypes.number.isRequired,
  borderColor: PropTypes.object.isRequired,
  imageHost: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  createdWithResponsiveEditor: PropTypes.bool,
  rootWidth: PropTypes.number,
  isRTL: PropTypes.bool.isRequired,
  hideAuthorBadge: PropTypes.bool,
  canSeeMoreButton: PropTypes.func.isRequired,
  layoutOptions: PropTypes.object,
  isTemplate: PropTypes.bool,
  hideCoverImage: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  isInIFrame: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  videoSettings: PropTypes.exact({
    play: PropTypes.string.isRequired,
    playbackSpeed: PropTypes.string.isRequired,
    sound: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    showPlayButton: PropTypes.bool.isRequired,
  }),
  useLayoutFixerExperimentEnabled: PropTypes.bool,
  isImageEncodingEnabled: PropTypes.bool,
  useResolvedCoverImage: PropTypes.bool,
  getPostClassName: PropTypes.func.isRequired,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
};

const getLayoutStyleParamProps = ({ state, section, hostWidth, rootWidth, layoutMargins, layoutType }) => {
  const contentHeightType = getLayoutContentHeightType({ state, section, layoutType });
  const isAutoHeightExperimentEnabled = isExperimentEnabled(state, EXPERIMENT_AUTO_HEIGHT);
  const layoutAutoHeightEnabled =
    isAutoHeightExperimentEnabled && contentHeightType === PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO;
  const selectorProps = { state, section, layoutType };
  return {
    layoutAutoHeightEnabled,
    layoutContentHeight: layoutAutoHeightEnabled
      ? calculateContentHeight(state, section, layoutType)
      : getLayoutContentHeight(selectorProps),
    layoutImageAlignment: getLayoutImageAlignment(selectorProps),
    layoutImageProportions: getLayoutImageProportions(selectorProps),
    layoutImageWidth: getLayoutImageWidth(selectorProps),
    layoutImageResizingMode: getLayoutImageResizingMode(selectorProps),
    layoutImageCropType: getLayoutImageCropType(selectorProps),
    layoutImageRatio: getLayoutImageRatio(selectorProps),
    ...(isExperimentEnabled(state, EXPERIMENT_PG_CARDS_PER_ROW)
      ? {
          layoutCardsRowType: getLayoutCardsRowType(selectorProps),
          layoutPostsPerRow: getLayoutPostsPerRow(selectorProps),
        }
      : {}),
    layoutPostSize: getLayoutPostSize({
      state,
      hostWidth,
      rootWidth,
      layoutMargins,
      section,
      layoutType,
    }),
    layoutSidesPadding: getLayoutSidesPadding(selectorProps),
    layoutSpacing: getLayoutSpacing(selectorProps),
  };
};

const mapRuntimeToProps = (
  state,
  { section, layoutType, layoutName, rootWidth, layoutDefaults = {} },
  actions,
  host,
) => {
  const hostWidth = host.dimensions.width;
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  const layoutMargins = createdWithResponsiveEditor
    ? null
    : getLayoutMargins({ state, section, layoutType, layoutName });
  const {
    layoutContentHeight,
    layoutImageAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    layoutImageRatio,
    layoutPostSize,
    layoutSidesPadding,
    layoutImageCropType,
    layoutSpacing,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutAutoHeightEnabled,
  } = getLayoutStyleParamProps({
    state,
    rootWidth,
    hostWidth,
    layoutMargins,
    section,
    layoutType,
  });

  return {
    scroll: state.scroll,
    isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
    onLikeClick: actions.incrementPostLikeCount,
    postPageSectionUrl: getPostPageSectionUrl(state),
    hostWidth,
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    borderColor: getFeedBorderColor({ state, section }),
    createdWithResponsiveEditor,
    isRTL: getIsRTL(state),
    formFactor: getIsMobile(state) ? GALLERY_CONSTS.formFactor.MOBILE : GALLERY_CONSTS.formFactor.DESKTOP,
    layoutContentHeight: isUndefined(layoutContentHeight) ? layoutDefaults.contentHeight : layoutContentHeight,
    layoutImageAlignment: isUndefined(layoutImageAlignment) ? layoutDefaults.imageAlignment : layoutImageAlignment,
    layoutImageProportions: isUndefined(layoutImageProportions)
      ? layoutDefaults.imageProportions
      : layoutImageProportions,
    layoutImageWidth: isUndefined(layoutImageWidth) ? layoutDefaults.layoutImageWidth : layoutImageWidth,
    layoutImageResizingMode: isUndefined(layoutImageResizingMode)
      ? layoutDefaults.layoutImageResizingMode
      : layoutImageResizingMode,
    layoutImageRatio: isUndefined(layoutImageRatio) ? layoutDefaults.imageRatio : layoutImageRatio,
    layoutImageCropType: isUndefined(layoutImageCropType) ? layoutDefaults.imageCropType : layoutImageCropType,
    layoutMargins: isUndefined(layoutMargins) ? layoutDefaults.margins : layoutMargins,
    layoutPostSize: isUndefined(layoutPostSize) ? layoutDefaults.layoutPostSize : layoutPostSize,
    layoutCardsRowType: isUndefined(layoutCardsRowType) ? layoutDefaults.layoutCardsRowType : layoutCardsRowType,
    layoutPostsPerRow: isUndefined(layoutPostsPerRow) ? layoutDefaults.layoutPostsPerRow : layoutPostsPerRow,
    layoutAutoHeightEnabled: isUndefined(layoutAutoHeightEnabled)
      ? layoutDefaults.layoutAutoHeightEnabled
      : layoutAutoHeightEnabled,
    layoutSidesPadding: isUndefined(layoutSidesPadding) ? layoutDefaults.sidesPadding : layoutSidesPadding,
    layoutSpacing: isUndefined(layoutSpacing) ? layoutDefaults.spacing : layoutSpacing,
    registerToScroll: host.registerToScroll,
    viewMode: getProGalleryViewMode(state, rootWidth),
    isTemplate: getIsTemplate(state),
    isDemoMode: getIsDemoMode(state),
    isInIFrame: getIsInIFrame(state),
    videoSettings: getVideoSettingsWithProGalleryValues(state, section),
    useMobilePostListLayout: getUseMobileLayoutSettings(state),
    isImageEncodingEnabled: isExperimentEnabled(state, EXPERIMENT_ADD_IMAGE_ENCODING),
  };
};

export default flowRight(
  withDeviceType,
  withFeedMetadataSettings,
  withTranslate,
  withResponsiveContext,
  withMediaHosts,
  connect(mapRuntimeToProps),
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  withExperiment({
    useLayoutFixerExperimentEnabled: EXPERIMENT_USE_LAYOUT_FIXER,
    useResolvedCoverImage: EXPERIMENT_USE_RESOLVED_COVER_IMAGE,
  }),
)(PostListProGallery);
