import React from 'react';
import PropTypes from 'prop-types';
import { getLayoutPostSize } from '../../../common/selectors/app-settings-selectors';
import { connect } from '../../../common/components/runtime-context';
import CreatePostButtonMobile from './create-post-button-mobile';
import PageTabs, { ALIGNMENT } from './page-tabs';
import styles from './my-posts-frame-mobile.scss';

const MyPostsFrameMobile = ({ children, layoutPostSize }) => (
  <div className={styles.root}>
    <PageTabs alignment={ALIGNMENT.center} />
    <div style={{ width: layoutPostSize }}>
      <div className={styles.createPostButton}>
        <CreatePostButtonMobile />
      </div>
      {children}
    </div>
  </div>
);

MyPostsFrameMobile.propTypes = {
  layoutPostSize: PropTypes.number.isRequired,
};

const mapRuntimeToProps = (state, _ownProps, _actions, host) => ({
  layoutPostSize: getLayoutPostSize({ state, hostWidth: host.dimensions.width }),
});

export default connect(mapRuntimeToProps)(MyPostsFrameMobile);
